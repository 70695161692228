.banner {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 180px 170px 0;
    box-sizing: border-box;
    position: relative;
}

.banner__title {
    text-shadow: 0px 34px 24px rgba(0, 0, 0, 0.85);
    /* font-family: Arimo; */
    font-size: 70px;
    font-style: normal;
    font-weight: 700;
    line-height: 90%;
    letter-spacing: -2.8px;
    max-width: 600px;
    position: relative;
    z-index: 1;
}

.banner__text {
    text-shadow: 0px 34px 24px rgba(0, 0, 0, 0.85);
    /* font-family: Azeret Mono; */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.56px;
    margin: 20px 0 0;
    max-width: 600px;
    position: relative;
    z-index: 1;
}

.banner__logo {
    width: 300px;
    height: 300px;
    position: absolute;
    z-index: 0;
    left: 0;
    top: 30px;

    user-select: none;
    pointer-events: none;
}

@media (max-width: 1000px) {

    .banner__logo {
        width: 200px;
        height: 200px;
        top: 70px;
        left: 0;
    }

    .banner{
        padding: 180px 80px 0;
    }

}

@media (max-width: 750px) {
    .banner {
        padding: 89px 20px 0;
        align-items: center;
        max-width: 400px;
    }

    .banner__title {
        text-align: center;
        text-shadow: 0px 34px 24px rgba(0, 0, 0, 0.85);
        font-size: 50px;
        font-style: normal;
        font-weight: 700;
        line-height: 84%;
        letter-spacing: -2px;

    }

    .banner__text {
        text-align: center;
        text-shadow: 0px 34px 24px rgba(0, 0, 0, 0.85);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 166%;
        letter-spacing: -0.48px;
    }

    .banner__logo {
        width: 150px;
        height: 150px;
        top: 8px;
        left: -12px;
    }
}