.footer {
    padding: 80px 170px 40px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.footer__copy {
    text-shadow: 0px 34px 24px rgba(0, 0, 0, 0.85);

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 142%;
    letter-spacing: -0.56px;
    opacity: 0.7;
}

.footer__email{
    text-shadow: 0px 34px 24px rgba(0, 0, 0, 0.85);

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 142%;
    letter-spacing: -0.56px;
    opacity: 0.7;
    text-align: right;
}

@media (max-width: 1000px) {
 .footer{
    padding: 80px 80px 40px;
 }
}

@media (max-width: 750px) {
 .footer{
    padding: 40px 20px 20px;
 }
 
}