.popup {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    box-sizing: border-box;
    padding: 24px;
    visibility: hidden;
    opacity: 0;
    z-index: 10000;
    transition: visibility 0.5s ease-in-out, opacity 0.4s ease-in-out;
}

.popup_opened {
    visibility: visible;
    opacity: 1;
    transition: visibility 0.5s ease-in-out, opacity 0.4s ease-in-out;
}

.popup__container {
    width: 100%;
    max-width: 1020px;
    min-height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background: #171717;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 40px;
    z-index: 1000;

}


@media (max-width: 750px) {
    .popup {
        padding: 0;

    }

    .popup__container {
        width: 100%;
        height: 100%;
        background-color: #000;
        padding: 24px;
        /* padding: 12px; */
    }

}