.app{
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
}

.app__content{
  max-width: 1280px;
  box-sizing: border-box;
  width: 100%;
}

@media (max-width: 1000px) {

}

@media (max-width: 750px) {

}