.business {
    padding: 0 130px;
    box-sizing: border-box;
    width: 100%;
    margin: 80px 0 0;
}

.business__content {
    width: 100%;
    padding: 40px 40px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background-color: #000;
    border-radius: 10px;

}

.business__title {
    text-shadow: 0px 34px 24px rgba(0, 0, 0, 0.85);
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -2px;
}

.business__text {
    margin: 32px 0 0;
    text-shadow: 0px 34px 24px rgba(0, 0, 0, 0.85);

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 142%;
    letter-spacing: -0.56px;

    opacity: 0.7;
    max-width: 600px;
}

.business__img {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 920 / 375;
    margin: 80px 0 0;
    user-select: none;
    pointer-events: none;
    object-position: bottom;

}

@media (max-width: 1000px) {
    .business {
        padding: 0;
    }
}


@media (max-width: 750px) {
    .business {
        margin: 40px 0 0;
    }

    .business__content {
        padding: 24px 0 0;
        align-items: center;
    }

    .business__title {
        text-align: center;

        text-shadow: 0px 34px 24px rgba(0, 0, 0, 0.85);

        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 90%;
        letter-spacing: -1.6px;
        max-width: 302px;
    }

    .business__text {
        margin: 16px 0 0;
        text-align: center;
        text-shadow: 0px 34px 24px rgba(0, 0, 0, 0.85);

        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;

        letter-spacing: -0.48px;
        padding: 0 24px;
        box-sizing: border-box;
    }


    .business__img-box{
        overflow: scroll;
        width: 100%;
    }
    .business__img {
        width: 926.381px;
        height: 375px;
        margin: 20px 0 0;
    }
}