.features {
    padding: 0 170px;
    box-sizing: border-box;
    width: 100%;
    margin: 80px 0 0;
    display: grid;
    grid-template-columns: repeat(3, minMax(0, 1fr));
    grid-template-rows: max-content;
    grid-auto-rows: max-content;
    column-gap: 50px;
    row-gap: 80px;
    grid-template-areas:
        'FIRST FIRST SECOND'
        'THIRD FOURTH FIFTH';
}

.features__card {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.features__card_type_1 {
    grid-area: FIRST;
    max-width: 445px;

}

.features__card-icon {
    width: 140px;
    height: 140px;
}

.features__card_type_1 .features__card-icon {
    width: 321px;
    height: 140px;
}

.features__card-title {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -0.96px;
    margin: 24px 0 0;
}

.features__card-text {
    margin: 16px 0 20px;
    text-shadow: 0px 34px 24px rgba(0, 0, 0, 0.85);
    white-space: pre-line;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 142%;

    letter-spacing: -0.56px;

    opacity: 0.7;
}

.features__card_type_2 {
    grid-area: SECOND;
}

.features__card_type_3 {
    grid-area: THIRD;
}

.features__card_type_4 {
    grid-area: FOURTH;
}


.features__card_type_5 {
    grid-area: FIFTH;
}

.features__card-btn {

    background-color: #000000;
    color: var(--text-color);
    max-width: fit-content;
    margin: auto 0 0;
    padding: 8px 12px;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 147%;
    letter-spacing: -0.56px;
    transition: all 0.2s ease-out;
}


.features__card-btn:hover {
    background-color: #803AC5;
}


@media (max-width: 1000px) {
    .features {
        column-gap: 30px;
        row-gap: 40px;
        padding: 0 80px;
    }

}


@media (max-width: 750px) {
    .features {
        grid-template-columns: repeat(2, minMax(0, 1fr));
        grid-template-areas:
            'FIRST FIRST'
            'SECOND THIRD'
            'FOURTH FIFTH';
        row-gap: 40px;
        padding: 0 20px;
        margin: 40px 0 0;
    }

    .features__card_type_1 .features__card-icon {
        width: 257px;
        height: 112px;
    }

    .features__card-icon {
        width: 112px;
        height: 112px;
    }

    .features__card-title {
        margin: 16px 0 0;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: -0.8px;
    }

    .features__card-text {
        margin: 12px 0;
        text-shadow: 0px 34px 24px rgba(0, 0, 0, 0.85);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: -0.48px;
    }

    .features__card-btn {
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 153%;
        letter-spacing: -0.52px;
    }
}