.feature-popup {
    display: flex;
    flex-direction: column;
    width: 100%;
}


.feature-popup__close {
    position: absolute;
    z-index: 1;
    right: 40px;
    top: 40px;
}

.feature-popup__close-icon {
    width: 18px;
    height: 18px;
}

.feature-popup__icon {
    width: 240px;
    height: 240px;
}

.feature-popup__title {
    text-shadow: 0px 34px 24px rgba(0, 0, 0, 0.85);
    margin: 40px 0 0;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -2px;
}

.feature-popup__text {
    text-shadow: 0px 34px 24px rgba(0, 0, 0, 0.85);
    margin: 40px 0 0;
    max-width: 600px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 142%;
    letter-spacing: -0.56px;
    opacity: 0.7;
    white-space: pre-line;
}

@media (max-width: 750px) {
 .feature-popup__close{
    top: 24px;
    right: 24px;
 }
 .feature-popup__icon{
    left: -7px;
    position: relative;
 }
}